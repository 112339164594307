<template>
  <base-section
    id="customer-reviews"
    class="grey lighten-5"
    space="64"
  >
    <base-section-heading
      icon="mdi-chat-processing"
      space="0"
      title="Avis clients"
    />

    <v-carousel
      class="mt-n12"
      height="600"
      cycle
      interval="10000"
      light
    >
      <v-carousel-item
        v-for="(customer, i) in customers"
        :key="i"
      >
        <v-container class="fill-height">
          <v-responsive
            class="mx-auto shrink text-center"
            max-width="90%"
            width="700"
          >
            <base-body
              :text="customer.review"
              tag="em"
              class="mb-6 d-block"
            />

            <v-avatar
              class="mb-6"
              size="128"
            >
              <base-img :src="customer.image" />
            </v-avatar>

            <base-title
              :title="customer.name"
              space="0"
            />

            <base-subtitle :title="customer.role" />
          </v-responsive>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCustomerReviews',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      customers: [
        {
          review: '"Je suis ravie de l\'aménagement de mon petit utilitaire. Titian a fait preuve d\'une belle ingéniosité pour utiliser tout le volume de mon véhicule et d\'un véritable sens de l\'esthétique pour les finitions. Ma maison de vacances sur quatre roues est un vrai petit bijou."',
          name: 'Anne-Romaine',
          role: 'Client aménagement de véhicules de voyage',
          image: require('@/assets/anne-romaine.jpg'),
        }, {
          review: '"Titian a modifié mon véhicule en corbillard exactement comme je l\'imaginais. Il suffit de donner son idée et il la réalise. Travail très soigné et précis. Un vrai plaisir de discuter avec ce professionnel remplit de talents."',
          name: 'Yvan',
          role: 'Client aménagement de véhicules utilitaires',
          image: require('@/assets/yvan.jpg'),
        }, {
          review: '"Pratique, mise en place rapide, cette installation me permet de vadrouiller sans hébergement. Je recommande Ironwood pour sa créativité et les qualité de ces services"',
          name: 'Mélissa',
          role: 'Cliente aménagement de véhicules de voyage',
          image: require('@/assets/melissa.jpg'),
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #customer-reviews
    .v-carousel__controls
      background: transparent

    .base-body
      line-height: 2
</style>
